import { graphql, PageProps } from 'gatsby'
import React from 'react'
import { Layout, Seo } from '../../components/commons'
import { PageContextProps, PageInfoLinkProps } from '../../types/pages'
import IntegrationHero from '../../components/resources/integrations/IntegrationHero'
import { GatsbyImageBasicProps } from '../../types/images'
import { ButtonProps } from '../../types/buttons'
import { IIntegration, IIntegrationCard, IUseCaseCard } from '../../types/resources'
import { SectionBase } from '../../types/sections'
import Section from '../../components/sections'
import Push from '../../components/sections/Push'
import FeaturedArticles from '../../components/sections/FeaturedArticles'
import FeaturedIntegrations from '../../components/sections/FeaturedIntegrations'
import FeaturedUseCases from '../../components/sections/FeaturedUseCases'
import { DestinationHowItWorks } from '../../components/resources/integrations'
import Testimonial from '../../components/sections/Testimonial'

interface DestinationQuery {
  destination: Pick<
    IIntegration,
    | 'id'
    | 'name'
    | 'pageInfo'
    | 'meta'
    | 'icon'
    | 'excerpt'
    | 'integrationType'
    | 'tags'
    | 'heroButton'
    | 'testimonials'
    | 'customSections'
    | 'push'
    | 'howItWorksHeadline'
    | 'howItWorksContent'
    | 'howItWorksButtons'
    | 'relatedArticles'
  >
  globals: {
    relatedArticlesHeadline: string
    relatedArticlesButton: ButtonProps[]
  }
  utils: {
    otherDestinationsHeadline: string
    otherDestinationsButton: ButtonProps[]
    destinationLabel: string
    integrationsLanding: PageInfoLinkProps
    useCasesIcon: GatsbyImageBasicProps
    useCasesHeadline: string
    useCasesButton: ButtonProps[]
  }
  useCases: {
    nodes: Array<IUseCaseCard>
  }
  otherDestinations: {
    nodes: Array<IIntegrationCard>
  }
}

const Destination: React.FC<PageProps<DestinationQuery, PageContextProps>> = ({ data, pageContext }) => {
  const { destination, otherDestinations, globals, utils, useCases } = data

  return (
    <Layout
      pageId={destination.id}
      locale={pageContext.locale}
      allSlugLocales={pageContext.slugLocales}
      withoutPrefooter={destination.pageInfo.hidePrefooter}
    >
      <Seo
        slug={destination.pageInfo.slug}
        canonicalUrl={destination.pageInfo.canonicalUrl}
        locale={pageContext.locale}
        allSlug={pageContext.slugLocales}
        publication={destination.meta}
        content={destination.pageInfo.seo}
      />

      <IntegrationHero
        tagLabel={utils.destinationLabel}
        tagLink={utils.integrationsLanding}
        integrationType={destination.integrationType}
        name={destination.name}
        excerpt={destination.excerpt}
        icon={destination.icon}
        tags={destination.tags}
        heroButton={destination.heroButton}
      />

      <DestinationHowItWorks
        headline={destination.howItWorksHeadline}
        buttons={destination.howItWorksButtons}
        content={destination.howItWorksContent}
        name={destination.name}
        icon={destination.icon}
      />

      {destination.customSections.map((s: SectionBase) => s?.model && <Section key={s.id} {...s} />)}

      {useCases.nodes.length > 0 && (
        <FeaturedUseCases
          sectionId={''}
          icon={utils.useCasesIcon}
          headline={utils.useCasesHeadline}
          button={utils.useCasesButton}
          useCases={useCases.nodes}
          id={''}
          model={{ apiKey: 's_featured_use_cases_list' }}
        />
      )}

      {destination.testimonials && destination.testimonials.length > 0 && (
        <Testimonial
          id={data.destination.testimonials[0].id}
          sectionId={''}
          model={{ apiKey: 's_testimonial' }}
          testimonial={data.destination.testimonials[0]}
        />
      )}

      {destination.relatedArticles.length > 0 && (
        <FeaturedArticles
          sectionId={''}
          headline={globals.relatedArticlesHeadline}
          button={globals.relatedArticlesButton}
          articles={destination.relatedArticles}
          id={'related-articles'}
          model={{ apiKey: 's_featured_articles' }}
        />
      )}

      {destination.push && (
        <Push sectionId={''} id={destination.push.id} model={{ apiKey: 's_push' }} push={destination.push} />
      )}

      {otherDestinations.nodes.length > 0 && (
        <FeaturedIntegrations
          sectionId={''}
          headline={utils.otherDestinationsHeadline}
          button={utils.otherDestinationsButton}
          integrations={otherDestinations.nodes}
          id={''}
          model={{ apiKey: 's_featured_integrations_list' }}
        />
      )}
    </Layout>
  )
}

export default Destination

export const query = graphql`
  query DestinationQuery($locale: String!, $id: String!, $integrationTags: [String!]!) {
    destination: datoCmsIntegration(locale: $locale, id: { eq: $id }) {
      id: originalId
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      name
      pageInfo {
        ...pageInfoFragment
      }
      integrationType
      icon {
        alt
        title
        gatsbyImageData(layout: CONSTRAINED, width: 240, placeholder: NONE)
      }
      heroButton {
        ...buttonFragment
      }
      excerpt
      tags {
        ...resourceTagFragment
      }
      howItWorksHeadline
      howItWorksButtons {
        ...buttonFragment
      }
      howItWorksContent
      customSections {
        ...sectionFeatureSimpleFragment
        ...sectionContentFragment
      }
      testimonials {
        ...testimonialFragment
      }
      push {
        ...pushFragment
      }
      relatedArticles {
        ...postPreviewWithCategoriesFragment
      }
    }
    utils: datoCmsResourcesGlobal(locale: $locale) {
      destinationLabel
      integrationsLanding: integrationsPageInfo {
        ...pageInfoLinkFragment
      }
      useCasesIcon {
        alt
        title
        gatsbyImageData(layout: CONSTRAINED, width: 80, placeholder: NONE)
      }
      useCasesHeadline: useCasesLabel
      useCasesButton: useCasesAllButton {
        ...buttonFragment
      }
      otherDestinationsHeadline: destinationsOtherLabel
      otherDestinationsButton: integrationsAllButton {
        ...buttonFragment
      }
    }
    globals: datoCmsGlobal(locale: $locale) {
      relatedArticlesHeadline: blogRelatedLabel
      relatedArticlesButton: blogAllButton {
        ...buttonFragment
      }
    }
    useCases: allDatoCmsUseCase(
      locale: $locale
      filter: { tags: { elemMatch: { id: { in: $integrationTags } } } }
      limit: 4
    ) {
      nodes {
        ...useCaseCardFragment
      }
    }
    otherDestinations: allDatoCmsIntegration(
      locale: $locale
      filter: {
        tags: { elemMatch: { id: { in: $integrationTags } } }
        integrationType: { eq: "destination" }
        id: { ne: $id }
      }
      limit: 4
    ) {
      nodes {
        ...integrationCardFragment
      }
    }
  }
`
